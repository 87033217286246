import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import { useLanguages } from "../hooks/useLanguages"
import loadable from '@loadable/component'
import CommonCarousel from '../components/Carousels/CommonCarousel'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'
import BlackArrowToRightIcon from '../components/BlackArrowToRightIcon'

const AdvertiseWithUsv1 = ({ location }) => {

    const CarouselContent = [
        {
            slideNo: 0,
            media: `<div id='embed-container'>
                          <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/10/earlysalary-testimony.webp" media='(min-width: 640px)' type="image/webp" alt="VC-Textile" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/10/earlysalary-testimony.webp" type="image/webp" alt="VC-Textile" />
                        </picture>
                    </div>`,
            quote: `At EarlySalary, we are looking to help young salaried professionals every time they fall short of cash. Our partnership with Vantage Circle has been instrumental in helping us reach 1 lakh loans and is one of the top partners for EarlySalary. Vantage Circle has helped us reach out and engage with a large corporate audience.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/07/akshay-mehrotra.png`,
            author: `Akshay Mehrotra`,
            designation: `Co-Founder & CEO, EarlySalary.com`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/07/ad_earlysalary.png`,
            truncateText: true,
            logoScale: 'scale-125'
        },
        {
            slideNo: 1,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/10/samsung-testimony.webp" media='(min-width: 640px)' type="image/webp" alt="VC-Textile" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/10/samsung-testimony.webp" type="image/webp" alt="VC-Textile" />
                        </picture>
                    </div>`,
            quote: `Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/default-avatar.webp`,
            author: `Rohit Malhotra`,
            designation: `Regional Head, Samsung Mobiles`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/07/ad_samsung.png`,
            truncateText: true,
            logoScale: 'scale-100'
        },
        {
            slideNo: 2,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/10/pepperfry-testimony.webp" media='(min-width: 640px)' type="image/webp" alt="VC-Textile" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/10/pepperfry-testimony.webp" type="image/webp" alt="VC-Textile" />
                        </picture>
                    </div>`,
            quote: `Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/default-avatar.webp`,
            author: `Amruta Gupta`,
            designation: `National Brand Head, Pepperfry`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/vantagecms/uploads/2020/07/ad_pepperfry.png`,
            truncateText: true,
            logoScale: 'scale-100'
        },
    ]

    const [currLanguage] = useLanguages()
    const [component, setcomponent] = useState()
    const [link, setlink] = useState("")
    const [mobileProductsOpen, setmobileProductsOpen] = useState(false)
    const [mobileResourcesOpen, setmobileResourcesOpen] = useState(false)
    const [mobileDropdown, setmobileDropdown] = useState(false)

    const showmobileDropdown = () => {
    setmobileDropdown(!mobileDropdown)
    }

    const handleClick = () => {
        setlink("https://www.youtube.com/embed/YyPgbiZZjsI?si=3ai-_kOdvG8BZW2x")
    }

    const images = [
        'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/adv-display1.webp',
        'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/adv-display2.webp',
        'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/adv-display3.webp',
        'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/adv-display4.webp'
    ]

    const checkWidth = () => {
        if(window !== 'undefined'){
            if(window.innerWidth <= 1024){             
                const EventImages= loadable(() => import('../components/Testimonials/AdvertiseCarousel'))
                setcomponent(<EventImages />)
            }
            else{
                const Theatre= loadable(() => import('../components/TheatreCarousel'))
                setcomponent(<Theatre  content={images}/>)
            }
        }
    }

    useEffect(() => {
        checkWidth()
    }, [])

  return (
    <Layout location={location} pageLanguage={currLanguage}>
        <section className="w-full shadow-darkgray bg-white flex place-content-center z-50 py-2 sticky top-0">
            <div className="w-full lg:w-11/12 2xl:w-9/12 flex justify-between px-5 2xl:px-24 items-center">
            <div className="w-1/2 md:w-2/5 lg:w-3/12 py-3 lg:py-3">
                <div>
                <img
                    className="w-auto vc-logo"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/11/vperks-logo.webp"
                    alt="Vantage Circle Logo"
                    width="250"
                    height="40"
                />
                </div>
            </div>
            <div className="hidden lg:flex justify-end items-center w-1/3 lg:w-10/12">
                <a
                href="#home"
                className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
                >
                Home
                </a>
                <a
                href="#whyUS"
                className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
                >
                Why Us?
                </a>
                <a
                href="#ads"
                className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
                >
                Top Advertisers
                </a>
                <a
                href="#steps"
                className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
                >
                Steps
                </a>
                <div className="flex px-0 xl:px-2 lg:w-2/6 xl:w-3/12 justify-center xl:justify-end items-center">
                <a
                    href="#form"
                    className="text-gray-100 bg-newOrange py-2 lg:px-2 xl:px-6 rounded-full mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center lg:text-base xl:text-lg"
                >
                    Book a Demo
                </a>
                </div>
            </div>

            <div
                className="flex lg:hidden justify-center items-center"
                onClick={showmobileDropdown}
            >
                <div className="rounded-full bg-white p-2 cursor-pointer">
                <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    strokewidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                </svg>
                </div>
            </div>
            </div>
            <div
            className={
                mobileDropdown
                ? `overflow-x-hidden overflow-y-scroll absolute top-16 mt-1 md:mt-4 w-full left-0 flex flex-col justify-start items-start p-6 bg-white dropdown shadow-darkgray rounded-2xs z-50` +
                    (mobileProductsOpen || mobileResourcesOpen ? " h-96" : "")
                : "hidden"
            }
            >
            <div className="w-full flex flex-col justify-center items-start">
                <a
                href="#home"
                className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
                >
                Home
                </a>
                <a
                href="#whyUS"
                className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
                >
                Why Us?
                </a>
                <a
                href="#ads"
                className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
                >
                Top Advertisers
                </a>
                <a
                href="#steps"
                className="text-gray-500 py-2 lg:px-2 xl:px-6 rounded-full m-0 mx-1 flex justify-center items-center lg:text-base xl:text-lg"
                >
                Steps
                </a>
                <a href="/request-demo/" className="w-full vc-btn-orange-v2 my-2">
                Book a Demo
                </a>
            </div>
            </div>
        </section>


        <section className='mb-16 lg:mb-28 px-5 pt-10 xl:pt-16 grid place-content-center w-11/12 lg:max-w-7xl mx-auto rounded-lg advertisev1BG' style={{ backgroundColor: '#eaebf3' }} id="home">
            <div className="my-2 grid justify-center items-center">
                <h2 className="text-center heroTagStyle">ADVERTISE WITH US</h2>
                <h1 class="xl:w-full homepage-heading text-center relative mt-5">Increase your <span className= "text-orange px-2" style ={{ backgroundColor: '#f0d2c5'}}>brand visibility</span> and sales <br/> among corporate audience</h1>
                <p className="mt-4 mb-8 xl:mt-6 xl:mb-8 text-gray-500 text-center w-full text-black max-w-3xl mx-auto" style= {{ lineHeight: '2rem' }}>
                    Advertise on Vantage Circle and reach out to the largest corporate audience across the globe in an organic and compelling way.
                </p>
                <a className="vc-new-orange-btn-rounded-full mx-auto text-white lato rounded-full mb-3 xl:mb-0" href="#form">Book a Demo</a>
            </div>
            <div className='flex justify-center opacity-0 max-w-xl h-350 lg:h-400'>
                {/* <picture>
                    <source className='z-10' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1300/q_auto/gatsbycms/uploads/2024/10/ad-with-us-hero.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="1000" />
                    <img className='z-10' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/10/ad-with-us-hero.webp" alt="Vantage Perks" width="400" />
                </picture> */}
            </div>
        </section>

        <section className='pb-10 xl:pt-4 xl:pb-16 w-full px-5' id="whyUS">
            <div className='max-w-7xl mx-auto'>
                <h2 className='homepage-section-heading text-center'>Why <span className='text-orange'>partner</span> with us?</h2>
                <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-10 mt-8 lg:mt-12'>
                    <div className='rounded-lg px-6 py-16 shadow grid justify-center'>
                        <ul className='orangebullets'>
                            <li className=' text-md text-gray-500 font-bold'>Amplify Your Brand Visibility</li>
                        </ul>
                        <p className='text-base text-gray-500'>
                        Elevate your brand to new heights. Our platform boosts your visibility, ensuring your brand stands out in today’s marketplace.
                        </p>
                    </div>
                    <div className='rounded-lg px-6 py-16 shadow grid justify-center'>
                        <ul className='orangebullets'>
                            <li className=' text-md text-gray-500 font-bold'>Reach Over 3.2 Million Corporate Professionals</li>
                        </ul>
                        <p className='text-base text-gray-500'>
                        Connect with a vast network of over 3.2 million corporate professionals who can propel your business forward.
                        </p>
                    </div>
                    <div className='rounded-lg px-6 py-16 shadow grid justify-center'>
                        <ul className='orangebullets'>
                            <li className=' text-md text-gray-500 font-bold'>Showcase Your Exclusive Offers</li>
                        </ul>
                        <p className='text-base text-gray-500'>
                        Promote your offers through corporate e-mailers, digital banners, App Notifications, and many more.
                        </p>
                    </div>
                    <div className='rounded-lg px-6 py-16 shadow grid justify-center'>
                        <ul className='orangebullets'>
                            <li className=' text-md text-gray-500 font-bold'>Drive Sales and Accelerate Growth</li>
                        </ul>
                        <p className='text-base text-gray-500'>
                        Transform engagement into revenue. Our platform not only increases your sales but also accelerates your business growth
                        </p>
                    </div>
                </div>
            </div>
        </section>

        {/* <section className='pb-10 xl:py-16 w-full'>
            <div className='py-12 lg:py-5 lg:flex justify-around items-center w-11/12 lg:max-w-7xl mx-auto bg-gray-newGrayDark p-5 xl:pr-3 rounded-lg'>
                <div className='w-full lg:w-1/2 lg:p-5 lg:pl-12'>
                    <h2 className='homepage-div-heading text-center lg:text-left'>Why partner with us?</h2>
                    <p className='mt-5 text-gray-500 text-center lg:text-left'>Our team works closely with you to create advertising packages as unique as your business.
    We help you reach the right audience in a way that gets them to take action.</p>
                    <p className='mt-5 text-gray-500 text-center lg:text-left'>Watch the video to see how it works</p>
                </div>
                <div className='w-full lg:w-7/12 lg:pl-7 lg:pr-2 mt-7 lg:mt-0'>
                    <div className='rounded-xl overflow-hidden cursor-pointer relative'>
                        <img className = {link.length ? 'hidden' : 'block'} onClick={handleClick} src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/01/career-page-vid-thumb-1.webp" width= "768px"/>
                        <div className=''><iframe className={link.length ? 'block' : 'hidden'} src={link} style= {{ maxWidth: '100%', maxHeight: '100%' }} width= "768" height="360" title="What's it like to Work at Vantage Circle ? | Corporate Culture | Careers at Vantage Circle" allowfullscreen /></div>
                    </div>
                </div>
            </div>
        </section> */}

        <section className='py-10 xl:pt-16 w-full px-5'>
            <div className='max-w-7xl mx-auto rounded-xl bg-indigo-100 px-5 py-10 xl:p-10 xl:py-16 xl:p-5 lg:grid grid-cols-2 gap-10'>
                <div className='grid place-content-center text-purple-100'>
                    <h2 className='homepage-small-heading text-center'>
                        Supercharge your brand's corporate presence now, starting from <span className='text-orange'>only INR 20,000!</span>
                    </h2>
                    <div className='rounded-xl overflow-hidden cursor-pointer relative w-5/6 mx-auto py-6'>
                        <img className = {link.length ? 'hidden' : 'block rounded-xl'} onClick={handleClick} src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/10/ad-with-us-video-thumbnail.webp" width= "768px"/>
                        <div className=''><iframe className={link.length ? 'block rounded-xl' : 'hidden'} src={link} style= {{ maxWidth: '100%', maxHeight: '100%' }} width= "768" height="360" title="What's it like to Work at Vantage Circle ? | Corporate Culture | Careers at Vantage Circle" allowfullscreen /></div>
                    </div>
                    <div className='grid grid-cols-3'>
                        <div className='text-center'>
                            <h2 className='text-orange homepage-small-heading pb-2'>3.2M+</h2>
                            <p className='text-purple-100 text-sm'>Corporate<br/> Employees</p>
                        </div>
                        <div className='text-center'>
                            <h2 className='text-orange homepage-small-heading pb-2'>700K+</h2>
                            <p className='text-purple-100 text-sm'>Newsletter<br/> Subscribers</p>
                        </div>
                        <div className='text-center'>
                            <h2 className='text-orange homepage-small-heading pb-2'>200K+</h2>
                            <p className='text-purple-100 text-sm'>Active<br/> App Users</p>
                        </div>
                    </div>
                </div>
                <div className='pt-10 lg:pt-0 advertiseForm'>
                        <div class="card-1 md:w-5/6 xl:w-5/6 mx-auto rnrForm xl:mt-0 flex flex-col place-items-center px-4 lg:px-6 py-7 lg:py-10 bg-indigo-50 frostedTransparentBG rounded-xl z-10 relative" style={{ backgroundColor: '#41426c' }} id="form">
                            <div class="w-full flex justify-center items-center lato rewards team">
                                <div class="flex justify-center items-center">
                                    <HubspotForm
                                    portalId="6153052"
                                    formId="7f48182b-b33f-4882-8cc2-3ff40d8cc0e4"
                                    onSubmit={() => console.log("Submit!")}
                                    onReady={form => console.log("Form ready!")}
                                    loading={<div>Loading...</div>}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>

        <section className='py-10 xl:py-16'>
            <div className='max-w-7xl mx-auto advertiseV1'>
                <h2 className='py-5 lg:pt-8 lg:pb-7 homepage-section-heading text-center mx-auto px-5'>Display your ads everywhere, anywhere.</h2>
                <p className="my-3 lg:mt-0 text-gray-500 text-center w-full text-black max-w-3xl mx-auto px-5" style= {{ lineHeight: '2rem' }}>
                    Advertise on Vantage Circle and reach out to the largest corporate audience across the globe in an organic and compelling way.
                </p>
                {component}
            </div>
        </section>

        {/* <section className='py-10 xl:py-16 w-full px-5'>
            <div className='max-w-5xl mx-auto '>
                <h2 className='homepage-section-heading max-w-3xl text-center mx-auto mb-10 lg:mb-16'>We are proud to be associated with these leading brands!</h2>
                <picture>
                    <source className='z-10 relative mx-auto' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1400/q_auto/gatsbycms/uploads/2024/02/adv-Partners-logo-Image-1.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="1000" />
                    <img className='z-10 relative mx-auto' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/02/adv-Partners-logo-Image-1.webp" alt="Vantage Perks" width="400" />
                </picture>
                <div class="lg:flex pt-12 xl:pt-16 xl:items-center"><div class="lg:w-2/12">
                    <picture>
                        <source class="mx-auto pb-0 transform scale-75 xl:scale-90 transform -translate-y-4" loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/2024/02/adv-testimony-1.webp" media="(min-width: 640px)" type="image/webp" alt="client strip" width="179" height="179" />
                        <img class="mx-auto pb-0 transform scale-75 xl:scale-90 transform -translate-y-4" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_230/q_auto/gatsbycms/uploads/2024/02/adv-testimony-1.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_230/q_auto/gatsbycms/uploads/2024/02/adv-testimony-1.webp" alt="client-strip" width="200" height="200" />
                    </picture>
                    <p class="m-0 pb-1 block lg:hidden text-center font-bold">Sameer Gadgil</p>
                    <p className='mb-6 block lg:hidden text-center'>Vice President &amp; HR Head, Wipro</p>
                </div>
                    <div class="md:w-9/12 mx-auto lg:mx-0 xl:w-10/12 lg:ml-8">
                        <p class="text-gray-200 italic">"At EarlySalary, we are looking to help young salaried professionals every time they fall short of cash. Our partnership with Vantage Circle has been instrumental in helping us reach 1 lakh loans and is one of the top partners for EarlySalary. Vantage Circle has helped us reach out and engage with a large corporate audience."</p>
                        <p class="mt-3 hidden lg:block"><span class="font-bold">Akshay Mehrotra</span>, Co-Founder & CEO, EarlySalary.com</p>
                    </div>
                </div>
            </div>
        </section> */}

        <section className='py-5 xl:pb-16 w-full px-5' id="ads">
            <div className='max-w-7xl mx-auto'>
                <h2 className='homepage-section-heading max-w-3xl text-center mx-auto mb-10 lg:mb-16'>We are proud to be associated with these leading brands!</h2>
                <picture>
                    <source className='z-10 relative mx-auto' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1400/q_auto/gatsbycms/uploads/2024/02/adv-Partners-logo-Image-1.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="1000" />
                    <img className='z-10 relative mx-auto' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/02/adv-Partners-logo-Image-1.webp" alt="Vantage Perks" width="400" />
                </picture>
                <div class="pt-12 xl:pt-28 w-full advertiseV1testimonial">
                    <CommonCarousel slideImages= {CarouselContent} />
                </div>
            </div>
        </section>

        <section className='w-full pt-10 xl:py-16' id="steps">
            <div className='bg-indigo-100 py-16 max-w-7xl lg:rounded-lg mx-auto px-5 md:px-10 lg:px-5 relative overflow-hidden px-5'>
                <h2 className='homepage-section-heading text-center text-purple-100'>Your <span className='text-orange'>path</span> to success here.</h2>
                <p className='text-center text-purple-100 mt-2'>Few more things to do once you onboard with us</p>
                <div className="grid lg:grid-cols-4 lg:w-11/12 mx-auto gap-4 mt-10">
                    {/* Marketing Plan Card */}
                    <div className="rounded-lg frostedIndigoBG shadow px-9 py-5 relative lg:mb-0 mb-6 text-center lg:text-left" style={{ zIndex: '8' }}>
                        <h2 className="text-lg md:text-xl xl:text-2xl py-3 text-purple-100 h-1/3">Marketing plan that fits best</h2>
                        <p className="py-5 text-purple-100 text-sm">Getting the perfect strategy for your brand.</p>
                        {/* Arrow for mobile/tablet, hidden on large screens */}
                        <div className="mx-auto mt-4 md:hidden absolute transform rotate-90" style={{ left: '43%' }}>
                            <BlackArrowToRightIcon />
                        </div>
                        <div className="mx-auto mt-4 hidden md:block lg:hidden absolute transform rotate-90" style={{ left: '47%' }}>
                            <BlackArrowToRightIcon />
                        </div>
                        {/* Arrow for desktop, hidden on small screens */}
                        <div className="hidden lg:flex absolute inset-y-0 -right-8 items-center justify-center">
                            <BlackArrowToRightIcon />
                        </div>
                    </div>

                    {/* Listing of Deals Card */}
                    <div className="rounded-lg frostedIndigoBG shadow px-9 py-5 relative lg:mb-0 mb-6 text-center lg:text-left" style={{ zIndex: '7' }}>
                        <h2 className="text-lg md:text-xl xl:text-2xl py-3 text-purple-100 h-1/3">Listing of Deals</h2>
                        <p className="py-5 text-purple-100 text-sm">List your product/service on our platform.</p>
                        <div className="mx-auto mt-4 md:hidden absolute transform rotate-90" style={{ left: '43%' }}>
                            <BlackArrowToRightIcon />
                        </div>
                        <div className="mx-auto mt-4 hidden md:block lg:hidden absolute transform rotate-90" style={{ left: '47%' }}>
                            <BlackArrowToRightIcon />
                        </div>
                        {/* Arrow for desktop, hidden on small screens */}
                        <div className="hidden lg:flex absolute inset-y-0 -right-8 items-center justify-center">
                            <BlackArrowToRightIcon />
                        </div>
                    </div>

                    {/* Ad Placing and Lead Generation Card */}
                    <div className="rounded-lg frostedIndigoBG shadow px-9 py-5 relative lg:mb-0 mb-6 text-center lg:text-left" style={{ zIndex: '6' }}>
                        <h2 className="text-lg md:text-xl xl:text-2xl py-3 text-purple-100 h-1/3">Ad placing and Lead generation</h2>
                        <p className="py-5 text-purple-100 text-sm">Promote your product/service using our marketing strategy and cultivate/acquire potential for your product/service.</p>
                        <div className="mx-auto mt-4 md:hidden absolute transform rotate-90" style={{ left: '43%' }}>
                            <BlackArrowToRightIcon />
                        </div>
                        <div className="mx-auto mt-4 hidden md:block lg:hidden absolute transform rotate-90" style={{ left: '47%' }}>
                            <BlackArrowToRightIcon />
                        </div>
                        {/* Arrow for desktop, hidden on small screens */}
                        <div className="hidden lg:flex absolute inset-y-0 -right-8 items-center justify-center">
                            <BlackArrowToRightIcon />
                        </div>
                    </div>

                    {/* Increase in Revenue Card */}
                    <div className="rounded-lg frostedIndigoBG shadow px-9 py-5 relative text-center lg:text-left" style={{ zIndex: '5' }}>
                        <h2 className="text-lg md:text-xl xl:text-2xl py-3 text-purple-100 h-1/3">Increase in revenue</h2>
                        <p className="py-5 text-purple-100 text-sm">Track your increase in revenue using analytics.</p>
                    </div>
                </div>

                <div
                    className="w-80 h-80 block absolute -bottom-16 transform rotate-90 -left-20 border rounded-full z-0"
                    style={{
                        borderWidth: "20px",
                        background: "transparent",
                        borderColor: "#8b8bc0",
                        // clipPath: "polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)",
                    }}
                ></div>
                <div
                    className="w-96 h-96 hidden lg:block absolute -top-1/3 transform -rotate-180 -right-20 border rounded-full z-0"
                    style={{
                        borderWidth: "20px",
                        background: "transparent",
                        borderColor: "#8b8bc0",
                        // clipPath: "polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)",
                    }}
                ></div>
            </div>
        </section>
    </Layout>
  )
}

export default AdvertiseWithUsv1